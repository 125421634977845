import React from 'react'

class Badge extends React.Component {
  render() {
    const { color, style, children } = this.props

    return (
      <p
        style={{
          color: 'white',
          fontSize: '75%',
          fontWeight: 700,
          backgroundColor: color,
          display: 'inline-block',
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 5.76,
          paddingRight: 5.76,
          borderRadius: 4,
          ...style,
        }}
      >
        {children}
      </p>
    )
  }
}

export default Badge
