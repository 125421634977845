import React from 'react'

import { rhythm } from '../utils/typography'

function Bio() {
  return (
    <div
      style={{
        display: `flex`,
        padding: 20,
        flexDirection: 'column',
        borderRadius: 10,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: '#dbdbdb',
        borderWidth: 0.5,
        backgroundColor: 'white',

      }}
    >
      <h2>
        <strong>Opa! Eai? 🙋🏻‍♂️</strong>
      </h2>
      <p>
        Aqui é o <strong>Nex</strong>! Sou um programador que está tentando entender o que é essa coisa de vida,
        eu escrevo basicamente sobre projetos e melhoria pessoal.
      </p>
      <p>
        Caso queira falar comigo basta <a href="mailto:ola@tnexblog.com">deixar um Oi</a>
        ! Você também pode me encontrar no <a href="https://github.com/thomazBDRI">Github</a> ou{` `}
        <a href="https://www.linkedin.com/in/thomaz-andrade-486342165/">LinkedIn</a>.
      </p>
    </div>
  )
}

export default Bio
