import React from 'react'
import { graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import Badge from '../components/badge'
import Share from '../components/share'
import Subscribe from '../components/subscribe'

import { DiscussionEmbed } from "disqus-react";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { badges } = post.frontmatter
    const disqusShortname = "tnexblog"
    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1>{post.frontmatter.title}</h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: rhythm(-0.5),
            marginBottom: rhythm(-0.5),
          }}
        >
          {badges && badges.map((badge) => (
            <Badge
              style={{
                marginRight: 5,
                fontSize: '120%',
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 9.6,
                paddingRight: 9.6,
              }}
              key={badge}
              color="#28a745"
            >
              {badge}
            </Badge>
          ))}
          <Badge
            key={post.frontmatter.date}
            style={{
              marginRight: 5,
              fontSize: '120%',
              paddingTop: 6,
              paddingBottom: 6,
              paddingLeft: 9.6,
              paddingRight: 9.6,
            }}
            color="#17a2b8"
          >
            {post.frontmatter.date}
          </Badge>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <Share url={this.props.location.href} size={32} distance={5} />
        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
          }}
        />
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        <Subscribe />
        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
          }}
        />
        <Bio />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY", locale: "pt-BR")
        badges
      }
    }
  }
`
