import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
} from 'react-share';

const Share = (props) => {
  return (
    <div style={{
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
    }}
    >
      <FacebookShareButton url={props.url} style={{ cursor: 'pointer' }}>
        <FacebookIcon size={props.size} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={props.url} style={{ marginLeft: props.distance, cursor: 'pointer' }}>
        <TwitterIcon size={props.size} round={true} />
      </TwitterShareButton>
      <TelegramShareButton url={props.url} style={{ marginLeft: props.distance, cursor: 'pointer' }}>
        <TelegramIcon size={props.size} round={true} />
      </TelegramShareButton>
      <WhatsappShareButton url={props.url} style={{ marginLeft: props.distance, cursor: 'pointer' }}>
        <WhatsappIcon size={props.size} round={true} />
      </WhatsappShareButton>
    </div>
  )
}

export default Share;

