import React from 'react'

import addToMailchimp from 'gatsby-plugin-mailchimp'
import { rhythm } from '../utils/typography'


export default class Subscribe extends React.Component {

  construct() {
    this.state = {
      data: '',
      email: '',
    }

    this._handleSubmit.bind(this)
  }

  _handleSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      console.log(data)
    })
      .catch( error =>
        console.log(error)
      )
  }

  render () {
    return (
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          padding: 20,
        }}
        onSubmit={this._handleSubmit}>
        <h2>Newsletter 📬</h2>
        <p style={{ textAlign: 'center' }}>Inscreva-se a baixo para ser notificado de atualizações! Não se preocupe, todas elas
          são escritas a mão e com muito carinho 💕</p>
        <input
          style={{
            padding: 5,
            borderRadius: 5,
            borderColor: '#dbdbdb',
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: 'white',
            color: 'black',
          }}
          type="text" onChange={event => this.setState({email: event.target.value})} />
        <small>Seu email não será compartilhado com ninguém.</small>
        <input style={{
          marginTop: 10,
          backgroundColor: '#17a2b8',
          color: 'white',
          borderWidth: 0,
          borderRadius: 5,
          paddingLeft: 10,
          paddingRight: 10
        }}
        type="submit"
        value="Inscrever" />
      </form>
    )
  }
}
